<template>
  <PageLayout :title="$t('emailCode.code.title')">
    <input-email-code-component
      class="email-code-view__input-email-code-component"
      :email="email"
      :is-loading="isButtonLoading"
      :error-message="errorMessage"
      @checkEmailCode="checkEmailCode"
      @clearErrorMessage="clearEmailCodeErrorMessage"
      @goToHome="returnHome"
    />
    <TermsFooterComponent class="email-code-view__terms-footer-component" />
  </PageLayout>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mParticleMetric } from '@/metrics';
import { AuthClient } from '@/services/models/authentication';
import { ROUTER_PATHS } from '@/router/paths';
import PageLayout from '@/components/PageLayout/PageLayout';
import TermsFooterComponent from '@/components/TermsFooterComponent/TermsFooterComponent.vue';

export default {
    components: {
        PageLayout,
        TermsFooterComponent,
        InputEmailCodeComponent: () =>
            import(
                /* webpackChunkName: "InputEmailCodeComponent"*/ '@/components/InputEmailCodeComponent/InputEmailCodeComponent'
            ),
    },
    props: {
        email: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            errorMessage: null,
            isButtonLoading: false,
            queryParams: null,
        };
    },
    beforeMount() {
        if (!this.email) {
            this.returnHome();
        }

        this.queryParams = this.$route.query;
    },
    methods: {
        clearEmailCodeErrorMessage() {
            this.errorMessage = null;
        },
        async checkEmailCode(inputs) {
            const { client } = this.$route.query;
            const emailCode = parseInt(inputs.join(''));
            const ERROR_VALUES = {
                422: 'emailCode.error-invalid',
                429: 'emailCode.error-try',
                500: 'emailCode.error-generic',
            };

            try {
                this.isButtonLoading = true;
                const { status, data } = await AuthClient.getLoginInfoWithEmail(this.email, emailCode, client);
                if (status === 200) {
                    const { code, userId, isNew } = data;

                    mParticleMetric.login({ customerid: userId });
                    mParticleMetric.track(mParticleMetric.EVENTS.LOGIN_CODE_VALIDATE, {
                        status: 'success',
                    });

                    this.$router.push({
                        path: ROUTER_PATHS.CROSSROADS,
                        query: { ...this.queryParams, code, userId, isNew },
                    });
                }
            } catch (error) {
                Sentry.captureMessage('Error checking email verification code');
                Sentry.captureException(error);
                mParticleMetric.track(mParticleMetric.EVENTS.LOGIN_CODE_VALIDATE, { status: 'error' });
                this.errorMessage = ERROR_VALUES[error.response.status];
                this.isButtonLoading = false;
            }
        },
        returnHome() {
            const { action } = this.$route.query;

            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action,
                },
            });
        },
    },

};
</script>

<style src="./EmailCodeView.scss" lang="scss" />