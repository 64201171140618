<template>
  <p class="terms-footer">
    <i18n path="legal_text">
      <a
        :href="externalURLs.terms"
        place="terms_of_use"
        class="simple-link"
        v-html="$t('legal_text.terms_of_use')"
      />
      <a
        :href="externalURLs.privacy"
        place="privacy_policy"
        class="simple-link"
        v-html="$t('legal_text.privacy_policy')"
      />
      <a
        :href="externalURLs.cookies"
        place="cookies_policy"
        class="simple-link"
        v-html="$t('legal_text.cookies_policy')"
      />
      <a
        :href="externalURLs.legal"
        place="legal"
        class="simple-link"
        v-html="$t('legal_text.legal')"
      />
    </i18n>
  </p>
</template>

<script>
import { URL_CONSTANTS } from '@/externalURLs';

export default {
    data() {
        return {
            externalURLs: {
                terms: URL_CONSTANTS.TERMS,
                privacy: URL_CONSTANTS.PRIVACY,
                cookies: URL_CONSTANTS.COOKIES,
                legal: URL_CONSTANTS.LEGAL,
            },
        };
    },
};
</script>

<style src="./TermsFooterComponent.scss" lang="scss" />
